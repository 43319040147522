// import React, { useEffect, useState, useRef } from 'react';
// import io from 'socket.io-client';
// import YouTube from 'react-youtube';

// function App() {
//     const [firstConnected, setFirstConnected] = useState(false);
//     const [callStatus, setCallStatus] = useState('JOIN');
//     const [localStream, setLocalStream] = useState(null);
//     const [remoteStreams, setRemoteStreams] = useState(null);
//     const [remotestream2, setremotestream2] = useState(null);
//     const [keysdata, setkeysdata] = useState([]);
//     const remoteRTCMessage = useRef(null);
//     const refvideoid = useRef(null);
//     const [videoid, setVideoid] = useState('');
//     const [ismuted, setismuted] = useState(1);
//     // const [isprocessCalled , setisprocessCalled] = useState(false);
//     // const [firstConnected, setFirstConnected] = useState(false);
//     // const [seekvalue , setseekvalue] = useState();

//     const [showText, setShowText] = useState(false);
//     const [idfromurl, setidfromurl] = useState('');
//     const [meetingidformurl, setmeetingurlfromurl] = useState('');

//     // useEffect(() => {
//     //   if (callStatus === 'WEBRTC_ROOM') {
//     //     setShowText(true);
//     //   } else {
//     //     setShowText(false);
//     //   }
//     // }, [callStatus]);



//     //  useEffect(() => {
//     //   // Parse URL parameters

//     //   if (id) {
//     //     setidfromurl(id);

//     //     setOtherUserId(id.toString());
//     //   }
//     //   if(meetingid){
//     //     setmeetingurlfromurl(meetingid);
//     //     setcallerId(meetingid.toString());
//     //   }
//     //   else {

//     //   setcallerId(Math.floor(100000 + Math.random() * 900000).toString());
//     // }
//     // }, []);


//     const socketref = useRef(null);
//     const peerConnection = useRef(null);
//     const peerConnection2 = useRef(null);
//     const [other2user, setOtherUserId2] = useState(null);
//     const other2userref = useRef(null)
//     const [player, setPlayer] = useState(null);
//     const [playclicked, setplayclicked] = useState(false);
//     const [pauseclicked, setpauseclicked] = useState(false);
//     let [callerId, setcallerId] = useState(null);
//     const [backclicked, setbackclicked] = useState(false);
//     const [forwardclicked, setforwardclicked] = useState(false);
//     const [layout, setlayout] = useState(null);
//     const [seekSeconds] = useState(10);
//     const [tvinteracted, settvinteracted] = useState(false);
//     const [seekcalled, setseekcalled] = useState(false);
//     const [seekvalue, setseekvalue] = useState(null);

//     const [socketseekcalled , setsocketseekcalled] = useState(false);
//     const [socketseekvalue , setsocketseekvalue] = useState(null);
//     const [seekseconduser , setseekseconduser] = useState('');
//     const socketseekvalueref = useRef(null);
//     const seekseconduserref = useRef(null);


//     const params = new URLSearchParams(window.location.search);
//     const id = params.get('id');
//     const meetingid = params.get('meetingid');
//     const onReady = (e) => {
//       setPlayer(e.target);


//   };


//     if (meetingid !== 'null') {
//         callerId = meetingid.toString();
//     }
//     else {
//         console.log('meeting is null')
//         callerId = Math.floor(100000 + Math.random() * 900000).toString();
//     }


//     console.log('Caller id is -->', callerId);
//     let [otherUserId, setOtherUserId] = useState(id);


//     useEffect(() => {

//         const socket = io('https://gabrialsignal.wingtrill.com/', {
//             transports: ['websocket'],
//             query: {
//                 callerId,
//             },
//         });
//         socketref.current = socket;

//         socketref.current.on('connect', () => {
//             socketref.current.user = 12334;
//         });
//         socketref.current.on('seek', data => {
//             const { value } = data;
//             setseekcalled(true);
//             setseekvalue(data.value);


//         });
//          socketref.current.on('userjoined', (data) => {
//             let callerId = data.callerId;
//             let firstusercallerid = data.firstusercallerid;

//             socketref.current.emit('userjoined', { callerId: callerId, firstusercallerid: id });



//         });
//         socketref.current.on('playClicked', () => {
//             setplayclicked(true);
//             // setpauseclicked(false);
//             setplayclicked(false);
//         });

//         socketref.current.on('syncvideo', data => {
//            let firstuser = data.firstuser;
//            let seconduser = data.seconduser;
//            let seekvalue = data.seekvalue;
//            setseekseconduser(seconduser);
//            socketseekvalueref.current = seekvalue;
//            seekseconduserref.current = seconduser;

//           //  if(seekvalue === 0){
//           //   const time = player.getCurrentTime();
//           //   socketref.current.emit('syncvideo', { firstuser: seconduser, seconduser: firstuser, seekvalue: time })
//           //  }else{
//           //      player.seekTo(seekvalue);

//           //  }
//         console.log('Sync is called 1 ' , firstuser);
//         console.log('Sync is called 2 ' , seconduser);
//         console.log('Sync is called 3 ' , seekvalue);
        
//         setsocketseekcalled(true);
//         setsocketseekvalue(data.seekvalue);
      
        
        
//         // seekToTime(seekvalue);

//         });






//         socketref.current.on('pauseClicked', () => {
//             // setplayclicked(false);
//             setpauseclicked(true);

//             setpauseclicked(false);
//         });

//         socketref.current.on('backClicked', () => {
//             setbackclicked(true);
//         });
//         socketref.current.on('forwardClicked', () => {
//             setforwardclicked(true);
//         });

        



//         return () => {
//             // if (socket.current) {
//             //   socket.current.disconnect();
//             // }
//         };

//     }, []);



//     useEffect(() => {
//         const handleKeyPress = (event) => {
//             // Log key code when pressing a key
//             console.log('Key code:', event.keyCode);
//             settvinteracted(true);
//             setkeysdata((prevEventCodes) => [...prevEventCodes, event.code]);
//         };

//         // Add event listener for keydown events
//         document.addEventListener('keydown', handleKeyPress);

//         // Cleanup: Remove event listener when component unmounts
//         return () => {
//             document.removeEventListener('keydown', handleKeyPress);
//         };
//     }, [callStatus]);







//     useEffect(() => {
//         socketref.current.on("receivelayoutcheck", (data) => {
//             let layoutnumber = data.layoutvalue;
//             console.log('Layout Value is >>>', layoutnumber);
//             if (layoutnumber !== null) {
//                 setlayout(layoutnumber);
//             }
//         })
//     }, [layout])










//     useEffect(() => {
//         function createFakeStream() {
//             let ctx = new AudioContext();
//             let oscillator = ctx.createOscillator();
//             let dst = oscillator.connect(ctx.createMediaStreamDestination());
//             oscillator.start();

//             let canvas = document.createElement("canvas");
//             canvas.width = 640;
//             canvas.height = 480;
//             canvas.getContext('2d').fillRect(0, 0, 640, 480);
//             let stream = canvas.captureStream();

//             const audioTrack = dst.stream.getAudioTracks()[0];
//             const videoTrack = stream.getVideoTracks()[0];

//             const fakeStream = new MediaStream([audioTrack, videoTrack]);

//             return fakeStream;
//         }

//         const fakeStream = createFakeStream();
//         setLocalStream(fakeStream);



//         peerConnection.current = new RTCPeerConnection({
//             iceServers: [
//                 {

//                     urls: ['turn:turn.hookzapp.com:3478'],
//                     credential: '12345678',
//                     username: 'jaspreet',
//                 }
//             ]
//         });


//         peerConnection2.current = new RTCPeerConnection({
//             iceServers: [
//                 {

//                     urls: ['turn:turn.hookzapp.com:3478'],
//                     credential: '12345678',
//                     username: 'jaspreet',
//                 }
//             ]
//         })


//         peerConnection.current.addEventListener("iceconnectionstatechange", (event) => {

//             console.log('Ice candidate Connection >>>>>', event);
//         });


//         peerConnection2.current.addEventListener("iceconnectionstatechange", (event) => {

//             console.log('Ice candidate Connection of peer connection 2 >>>>>', event);
//         });

//         if (peerConnection.current) {
//             peerConnection.current.addEventListener('connectionstatechange', () => {
//                 if (peerConnection.current.connectionState === 'connected') {
//                     setFirstConnected(true);
//                 }
//             });
//         }

//         RTCPeerConnection.oniceconnectionstatechange = (event) => {

//             console.log('Ice candidate Connection >>>>>', event);




//         };

//         peerConnection.current.addEventListener("connectionstatechange", (event) => {
//             console.log('Connection state Connection >>>>>', event);
//         });
//         peerConnection2.current.addEventListener("connectionstatechange", (event) => {
//             console.log('Connection state Connection peer connection 2 >>>>>', event);
//         });

//         RTCPeerConnection.onconnectionstatechange = (event) => {
//             console.log('Connection state Connection >>>>>', event);
//         };


//         peerConnection.current.addStream(fakeStream);
//         peerConnection2.current.addStream(fakeStream);


//         peerConnection.current.onaddstream = event => {
//             setRemoteStreams(event.stream);
//         };
//         peerConnection2.current.onaddstream = event => {
//             setremotestream2(event.stream);
//         };

//         peerConnection.current.onicecandidate = event => {
//             if (event.candidate) {
//                 sendICEcandidate({
//                     calleeId: otherUserId,
//                     rtcMessage: {
//                         label: event.candidate.sdpMLineIndex,
//                         id: event.candidate.sdpMid,
//                         candidate: event.candidate.candidate,
//                     },
//                 });
//             } else {
//                 console.log('End of candidates.');
//             }
//         };
//         peerConnection2.current.onicecandidate = event => {
//             if (event.candidate) {
//                 sendICEcandidate({
//                     calleeId: other2userref.current,
//                     rtcMessage: {
//                         label: event.candidate.sdpMLineIndex,
//                         id: event.candidate.sdpMid,
//                         candidate: event.candidate.candidate,
//                     },
//                 });
//             } else {
//                 console.log('End of candidates.');
//             }
//         };

//         socketref.current.on('newCall', data => {
//             remoteRTCMessage.current = data.rtcMessage;
//             setOtherUserId(data.callerId);
//             if (data.callerId !== otherUserId) {
//                 other2userref.current = data.callerId;
//                 // setisprocessCalled(true);
//                 console.log('Other User Id is -->' ,  other2userref.current);
//               //  processAccept();
//                   socketref.current.emit('sendVideoId', { videoId: refvideoid.current, otherUserId: other2userref.current });
               
             


//             }
//             // setCallStatus('INCOMING_CALL');
            
//                 processAccept();
       

//             // setisprocessCalled(true);


//         });
//         socketref.current.on('callAnswered', data => {
//             remoteRTCMessage.current = data.rtcMessage;
//             peerConnection.current
//                 .setRemoteDescription(new RTCSessionDescription(remoteRTCMessage.current))
//                 .then(() => {
//                     setCallStatus('WEBRTC_ROOM');
//                     console.log('Remote description set successfully.');
//                 })
//                 .catch(error => console.error('Error setting remote description:', error));
//         });

//         socketref.current.on('callAnswered', data => {
//             remoteRTCMessage.current = data.rtcMessage;
//             peerConnection2.current
//                 .setRemoteDescription(new RTCSessionDescription(remoteRTCMessage.current))
//                 .then(() => {
//                     setCallStatus('WEBRTC_ROOM');
//                     console.log('Remote description set successfully.');
//                 })
//                 .catch(error => console.error('Error setting remote description:', error));
//         });

//         socketref.current.on('ICEcandidate', data => {
//             let message = data.rtcMessage;
//             console.log("Received ICE candidate:", data);
//             if (peerConnection.current) {
//                 peerConnection.current
//                     .addIceCandidate(
//                         new RTCIceCandidate({
//                             candidate: message.candidate,
//                             sdpMid: message.id,
//                             sdpMLineIndex: message.label,
//                         })
//                     )
//                     .then(data => {
//                         console.log('SUCCESS');
//                     })
//                     .catch(err => {
//                         console.log('Error', err);
//                     });
//             }




//         });


//         socketref.current.on('ICEcandidate', data => {
//             let message = data.rtcMessage;
//             console.log("Received ICE candidate:", data);
//             if (peerConnection2.current) {
//                 peerConnection2.current
//                     .addIceCandidate(
//                         new RTCIceCandidate({
//                             candidate: message.candidate,
//                             sdpMid: message.id,
//                             sdpMLineIndex: message.label,
//                         })
//                     )
//                     .then(data => {
//                         console.log('SUCCESS');
//                     })
//                     .catch(err => {
//                         console.log('Error', err);
//                     });
//             }



//         });

//         socketref.current.on('userjoined', (data) => {
//             let callerId = data.callerId;
//             let firstusercallerid = data.firstusercallerid;

//             socketref.current.emit('userjoined', { callerId: callerId, firstusercallerid: id });



//         });
//         socketref.current.on('receiveVideoId', data => {
//             const { videoId } = data;
//             console.log('Video Id is >>', videoId);
//             if (videoId !== '' || videoId !== null) {
            
//                     setVideoid(videoId);
                

//                 refvideoid.current = videoId;
//                 // if(other2userref.current !== null){
//                 //    socketref.current.emit('sendVideoId', { videoId: refvideoid.current  , otherUserId: other2userref.current}); 
//                 // }


//             }
//         });
//         // if (videoid !== null && videoid !== '' && other2userref.current !== null) {
//         //   socketref.current.emit('sendVideoId', { videoId: videoid  , otherUserId: other2userref.current});
//         // }



//         return () => {
//             socketref.current.off('newCall');
//             socketref.current.off('callAnswered');
//             socketref.current.off('ICEcandidate');
//             socketref.current.off('userjoined');
//             socketref.current.off('receiveVideoId');

//         };
//     }, []);
   

//     useEffect(() => {
//         if (peerConnection.current && peerConnection.current.remoteDescription) {

//             if (peerConnection.current.pendingIceCandidates) {
//                 peerConnection.current.pendingIceCandidates.forEach(candidate => {
//                     peerConnection.current
//                         .addIceCandidate(new RTCIceCandidate(candidate))
//                         .then(() => {
//                             console.log('Pending ICE candidate added successfully.');
//                         })
//                         .catch(error => {
//                             console.error('Error adding pending ICE candidate:', error);
//                         });
//                 });
//                 peerConnection.current.pendingIceCandidates = [];
//             }
//         }
//     }, [peerConnection.current ? peerConnection.current.remoteDescription : null]);


//     // socketref.current.on('userjoined', (data) => {
//     //       let callerId = data.callerId;
//     //       let firstusercallerid = data.firstusercallerid;

//     // socketref.current.emit('userjoined', {callerId: callerId, firstusercallerid : id});



//     //     });
//     async function sendICEcandidate(data) {
//         socketref.current.emit('ICEcandidate', data);
//     }

//     async function processCall() {
//         const sessionDescription = await peerConnection.current.createOffer();
//         await peerConnection.current.setLocalDescription(sessionDescription);
//         sendCall({
//             calleeId: otherUserId,
//             rtcMessage: sessionDescription,
//         });
//     }
    // useEffect(() => {
    //     if (otherUserId !== '' && callStatus === 'JOIN' && callerId !== null) {
    //            setTimeout(()=>{
    //             processCall();
    //            },4000);
               
       


    //     }
    // },[])


//     async function processAccept() {

//       console.log('inside the Process Accept !!!')
//         peerConnection2.current
//             .setRemoteDescription(new RTCSessionDescription(remoteRTCMessage.current))
//             .then(() => {
//                 peerConnection2.current.createAnswer().then(answer => {
//                     peerConnection2.current
//                         .setLocalDescription(answer)
//                         .then(() => {
//                             answerCall({
//                                 callerId: other2userref.current,
//                                 rtcMessage: answer,
//                             });
//                             setCallStatus('WEBRTC_ROOM');
//                         })
//                         .catch(error => {
//                             console.error('Error setting local description:', error);
//                         });
//                 });
//             })
//             .catch(error => {
//                 console.error('Error setting remote description:', error);
//             });
//     }

//     useEffect(() => {
//         if (refvideoid.current !== null && other2userref.current !== null) {

//             // processAccept();

//             socketref.current.emit('sendVideoId', { videoId: refvideoid.current, otherUserId: other2userref.current });

//         }

//     }, [refvideoid.current, other2userref.current])











//     function answerCall(data) {
//         socketref.current.emit('answerCall', data);
//     }

//     function sendCall(data) {
//         socketref.current.emit('call', data);
//     }






    

//     useEffect(() => {
//         // Play the video continuously until its current time is greater than 0
//         const playContinuously = () => {
//             if (player && player.getCurrentTime() === 0) {
//                 player.playVideo();
//                 requestAnimationFrame(playContinuously);
//             }
//         };

//         playContinuously();
//     },);

//     useEffect(() => {
//         if (callStatus === 'WEBRTC_ROOM' && firstConnected && meetingid !== 'null') {

//             const updateStatusToStarted = async () => {
//                 try {
//                     const response = await fetch(`https://wwm-backend.wingtrill.com/api/workouts/status/${meetingid}`, {
//                         method: 'PUT',
//                         headers: {
//                             'Content-Type': 'application/json'
//                         },
//                         body: JSON.stringify({ status: 'started' })
//                     });

//                     if (!response.ok) {
//                         throw new Error('Failed to update status to started');
//                     }

//                     console.log('Status updated to started successfully');
//                 } catch (error) {
//                     console.error('Error updating status to started:', error);
//                 }
//             };

//             updateStatusToStarted();
//         }
//     }, [callStatus, firstConnected, meetingid]);


//     const seekToTime = (time) => {
//         console.log('Seek Value is Outside function --->', time)

//         console.log('Seek Value is inside function --->', time)
//         player.seekTo(time*0.016);

//     };

//     const onSeekHandler = (value) => {
//         if (value && value !== undefined) {
//             // const { value } = data;

//             console.log('Seek Value is -->', value);
//             player.seekTo(value);

//         } else {
//             console.error('Invalid data for seeking.');
//         }
//     };



//     const onPlayHandler = () => {



//         const currentTime = player.getCurrentTime();
//         player.playVideo();





//     };
//     const onPauseHandler = () => {
//         player.pauseVideo();
//     };

//     const unmuteVideo = () => {
//         if (player) {
//             player.unMute();
//         }
//     };



//     useEffect(() => {
//         if (playclicked) {
//             onPlayHandler();


//         }
//     }, [playclicked]);

    
//     useEffect(() => {
//         if (pauseclicked) {
//             onPauseHandler();
//         }
//     }, [pauseclicked]);




//     const seekForward = () => {
//         const currentTime = player.getCurrentTime();
//         player.seekTo(currentTime + seekSeconds);
//     };

//     const seekBackward = () => {
//         // unmuteVideo();
//         const currentTime = player.getCurrentTime();
//         player.seekTo(currentTime - seekSeconds);
//     };
//     const seekbar = () => {
//         // unmuteVideo();
//         // const currentTime = player.getCurrentTime();
//         console.log('seeking to the value in function -->', seekvalue)
//         player.seekTo(seekvalue);
//     };

//     const handlesocketseek = () =>{
//         console.log('Value is 243434-->' , socketseekvalue);
//         console.log('Value of refrence socketseek value-->' , socketseekvalueref.current);
        
//             player.seekTo(socketseekvalueref.current);
//             if(seekseconduserref.current !== null){
//                 socketref.current.emit('syncvideo', { firstuser:  seekseconduserref.current, seconduser: null, seekvalue: socketseekvalueref.current })
//             }
         
         
        
        
        
//        }
   
   
  
//     useEffect(() => {
//         if (backclicked) {

//             seekBackward();
//             setbackclicked(false);
//         }
//     }, [backclicked]);

//     useEffect(() => {
//         if (seekcalled) {
//             seekbar();
//             setseekcalled(false);
//         }
//     }, [seekcalled]);

//     useEffect(()=>{
//    if(socketseekcalled){
//         handlesocketseek();
//        setsocketseekcalled(false);
//      }
//     },[socketseekcalled])
//     useEffect(() => {
//         if (forwardclicked) {
//             seekForward();
//             setforwardclicked(false);
//         }
//     }, [forwardclicked]);

//     console.log('Layout Value Change >>>', layout)



//     return (
//         <div>
//             {callStatus === 'JOIN' && (
//                 <div style={{ width: '100%', height: '100%', flex: 1, justifyContent: 'center', textAlign: 'center' }}>
//                     <h1>Please Wait We Are Connecting....</h1>
//                     <h1>User  Value is: {id.toString()}</h1>



//                     <button onClick={processCall}>Call</button>
//                 </div>
//             )}
//             {callStatus === 'INCOMING_CALL' && (

//                 <div>
//                     <h1>Incoming Call from {other2userref.current}</h1>
//                     <button onClick={processAccept}>Answer</button>
//                 </div>
//             )}
//             {callStatus === 'OUTGOING_CALL' && (
//                 <div>
//                     <h1>Calling {otherUserId}</h1>
//                     <button>Hang Up</button>
//                 </div>
//             )}
//             {/* {callStatus === 'WEBRTC_ROOM' && (
//         <div>
//           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//             <div style={{ width: '80%' }}>
//               <iframe
//                 title="YouTube Video"
//                 width="100%"
//                 height="100%"
//                 src={`https://www.youtube.com/embed/${videoid}?autoplay=1&mute=1&enablejsapi=1`}
//                 frameBorder="0"
//                 allowFullScreen
//                 autoPlay
//               ></iframe>
//             </div>
//             <div style={{ flex: 1, marginBottom: '-5px' }}>
//   {localStream && (
//     <video autoPlay muted={true} style={{ width: '100%' }} ref={video => (video && (video.srcObject = localStream))} />
//   )}
//   {
//   remotestream2 && (
//     <video autoPlay muted={true} style={{ width: '100%' }} ref={video => (video && (video.srcObject = remotestream2))} />
//   )
// }
// </div>

// {remoteStreams.map((stream, index) => (
//   <div key={index} style={{ flex: 1 }}>
//     {stream && (
//       <video autoPlay muted={true} style={{ width: '100%', height: '100%' }} ref={video => (video && (video.srcObject = stream))} />
//     )}
//   </div>
// ))}

//           </div>
//         </div>
//       )} */}


//             {callStatus === 'WEBRTC_ROOM' && (
//                 <div style={{ height: '100%' }}>
//                     <p style={{ textAlign: 'center', color: 'white', fontSize: 30 }}>Note : Press the Ok Button on Tv Remote to Play the youtube Video</p>
//                     {meetingid !== 'null' &&
//                         <p style={{ fontSize: 10 }}> Meeting id is -- {meetingid.toString()}</p>
//                     }
//                     {/* {tvinteracted && <p style={{ textAlign:'center' , color:'white' , fontSize:30 }}>Tv interacted !!</p>} */}
//                     {/* <ul>
//         {keysdata.map((code, index) => (
//           <li key={index}>{code}</li>
//         ))}
//       </ul> */}
//                     {layout === 0 ? (
//                         // Layout 0: YouTube video on the left half with full height and remote streams (excluding remotestream2) on the right half
//                         <div style={{ display: 'flex', height: '100%' }}>
//                             {/* YouTube Video */}
//                             <div style={{ flex: 1 }}>
//                                 <YouTube
//                                     videoId={videoid}
//                                     onReady={onReady}
//                                     opts={{
//                                         playerVars: {
//                                             // autoplay:1,
//                                             controls: 1,
//                                             // mute:1

//                                         },
//                                     }}
//                                 />
//                             </div>

//                             {/* Remote Streams (excluding remotestream2) */}
//                             <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
//                                   {
//                                         remoteStreams && (
//                                             <div style={{ flex: 1 }}>
//                                                 <video autoPlay style={{ width: '100%', height: '100%' }} ref={video => (video && (video.srcObject = remoteStreams))} />
//                                             </div>
//                                         )
//                                     }
//                             </div>
//                         </div>
//                     ) : layout === 1 ? (
//                         // Layout 1: YouTube video and remotestream2 on top, remote streams below
//                         <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
//                             {/* Top Row: Two small boxes (YouTube video and remotestream2) */}
//                             <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
//                                 {/* YouTube Video */}
//                                 <div style={{ flex: 1, marginRight: '5px' }}> {/* Add margin between the two blocks */}
//                                     <YouTube
//                                         videoId={videoid}
//                                         onReady={onReady}
//                                         opts={{
//                                             playerVars: {
//                                                 // autoplay:1,
//                                                 controls: 1,
//                                                 // mute:1

//                                             },
//                                         }}
//                                         style={{ width: '50%', height: '50%' }} // Adjust width and height as needed


//                                     />

//                                 </div>


//                                 <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
//                                     {remoteStreams.map((stream, index) => (
//                                         <div key={index} style={{ width: '50%' }}> {/* Center the block and set its width */}
//                                             {stream && (
//                                                 <video
//                                                     autoPlay

//                                                     style={{ width: '100%', height: '100%' }}
//                                                     ref={(video) => video && (video.srcObject = stream)}
//                                                 />
//                                             )}
//                                         </div>
//                                     ))}
//                                 </div>
//                                 {/* RemoteStream2 */}
//                                 <div style={{ flex: 1, marginLeft: '5px' }}> {/* Add margin between the two blocks */}
//                                     {remotestream2 && (
//                                         <video
//                                             autoPlay

//                                             style={{ width: '100%', height: '100%' }}
//                                             ref={(video) => video && (video.srcObject = remotestream2)}
//                                         />
//                                     )}
//                                 </div>
//                             </div>

//                             {/* Bottom Row: One larger block (Remote stream) */}

//                         </div>

//                     ) : (
//                         // Layout 2: YouTube video, remotestream2, and remote streams displayed in a row
//                         <div style={{}}>

//                             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//                                 {/* YouTube Video */}
//                                 <div style={{ width: '80%', height: '103%' }}> {/* Adjusted width */}



//                                     {videoid !== '' &&
//                                         <YouTube
//                                             videoId={videoid}
//                                             onReady={onReady}

//                                             opts={{
//                                                 playerVars: {
//                                                     // autoplay:1,
//                                                     controls: 1,
//                                                     // mute:1



//                                                 },
//                                             }}

//                                         />
//                                     }




//                                 </div>



//                                 {/* Local and Remote Streams */}
//                                 <div style={{ width: '12vw', display: 'flex', flexDirection: 'column' }}>
//                                     <div style={{ flex: 1, marginBottom: '-5px' }}>

//                                         {
//                                             remotestream2 && (
//                                                 <video autoPlay style={{ width: '100%' }} ref={video => (video && (video.srcObject = remotestream2))} />
//                                             )
//                                         }
//                                     </div>

//                                     {
//                                         remoteStreams && (
//                                             <div style={{ flex: 1 }}>
//                                                 <video autoPlay style={{ width: '100%', height: '100%' }} ref={video => (video && (video.srcObject = remoteStreams))} />
//                                             </div>
//                                         )
//                                     }



//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             )}





//         </div>
//     );
// }
import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import YouTube from 'react-youtube';

function App() {
    const [firstConnected, setFirstConnected] = useState(false);
    const [callStatus, setCallStatus] = useState('JOIN');
    const [localStream, setLocalStream] = useState(null);
    const [remoteStreams, setRemoteStreams] = useState(null);
    const [remotestream2, setremotestream2] = useState(null);
    const [keysdata, setkeysdata] = useState([]);
    const remoteRTCMessage = useRef(null);
    const refvideoid = useRef(null);
    const [videoid, setVideoid] = useState('');
    const [ismuted, setismuted] = useState(1);
    // const [isprocessCalled , setisprocessCalled] = useState(false);
    // const [firstConnected, setFirstConnected] = useState(false);
    // const [seekvalue , setseekvalue] = useState();



    // const [stats, setStats] = useState({
    //     network: {},
    //     performance: {},
    //     webRTC: {},
    // });


    // const [stats2, setStats2] = useState({
    //     network: {},
    //     performance: {},
    //     webRTC: {},
    // })

    const [showText, setShowText] = useState(false);
    const [idfromurl, setidfromurl] = useState('');
    const [meetingidformurl, setmeetingurlfromurl] = useState('');
 

    // useEffect(() => {
    //   if (callStatus === 'WEBRTC_ROOM') {
    //     setShowText(true);
    //   } else {
    //     setShowText(false);
    //   }
    // }, [callStatus]);



    //  useEffect(() => {
    //   // Parse URL parameters

    //   if (id) {
    //     setidfromurl(id);

    //     setOtherUserId(id.toString());
    //   }
    //   if(meetingid){
    //     setmeetingurlfromurl(meetingid);
    //     setcallerId(meetingid.toString());
    //   }
    //   else {

    //   setcallerId(Math.floor(100000 + Math.random() * 900000).toString());
    // }
    // }, []);


    const socketref = useRef(null);
    // const peerConnection = useRef(null);
    // const peerConnection2 = useRef(null);
    const [other2user, setOtherUserId2] = useState(null);
    const other2userref = useRef(null)
    const [player, setPlayer] = useState(null);
    const [playclicked, setplayclicked] = useState(false);
    const [pauseclicked, setpauseclicked] = useState(false);
    let [callerId, setcallerId] = useState(null);
    const [backclicked, setbackclicked] = useState(false);
    const [forwardclicked, setforwardclicked] = useState(false);
    const [layout, setlayout] = useState(null);
    const [seekSeconds] = useState(10);
    const [tvinteracted, settvinteracted] = useState(false);
    const [seekcalled, setseekcalled] = useState(false);
    const [seekvalue2, setseekvalue2] = useState(null);

    const [socketseekcalled , setsocketseekcalled] = useState(false);
    const [socketseekcalled2 , setsocketseekcalled2] = useState(false);
    const [socketseekvalue , setsocketseekvalue] = useState(null);
    const [socketseekvalue2 , setsocketseekvalue2] = useState(null);
    const [seekseconduser , setseekseconduser] = useState('');
    const socketseekvalueref = useRef(null);
    const seekseconduserref = useRef(null);
    const seeekfirstuserref = useRef(null);

    const socketseekvalueref2 = useRef(null);
    const seekseconduserref2 = useRef(null);
    const seeekfirstuserref2 = useRef(null);

    const continousseekvalue = useRef(null);
    const [seekvalue , setseekvalue] = useState(null);
    const socketvalueseek = useRef(null);
    const peerConnection = useRef(null);
    const peerConnection2 = useRef(null);

    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    const meetingid = params.get('meetingid');
    const onReady = (e) => {
      setPlayer(e.target);


  };


    if (meetingid !== 'null') {
        callerId = meetingid.toString();
    }
    else {
        console.log('meeting is null')
        callerId = Math.floor(100000 + Math.random() * 900000).toString();
    }


    console.log('Caller id is -->', callerId);
    let [otherUserId, setOtherUserId] = useState(id);


    useEffect(() => {
        if (!peerConnection.current) {
            peerConnection.current = new RTCPeerConnection({
                iceServers: [
                    {
                        urls: ['turn:turn-dig.hookzapp.com:3478'],
                        credential: '12345678',
                        username: 'jaspreet',
                    }
                ]
            });
            console.log('Creating Peer Connection')
        }

        if (!peerConnection2.current) {
            peerConnection2.current = new RTCPeerConnection({
                iceServers: [
                    {
                        urls: ['turn:turn-dig.hookzapp.com:3478'],
                        credential: '12345678',
                        username: 'jaspreet',
                    }
                ]
            });
            console.log('Creating Peer Connection2')
        }

        // Clean up function to close peer connections when the component unmounts
        return () => {
           
        };
    }, []);

    // useEffect(() => {


    //     // Function to get network stats
    //     const getNetworkStats = async () => {
    //         const stats = await peerConnection.current.getStats();
    //         let networkStats = {};
    //         stats.forEach(report => {
    //             if (report.type === 'outbound-rtp' || report.type === 'inbound-rtp') {
    //                 networkStats[report.id] = report;
    //             }
    //         });
    //         return networkStats;
    //     };

    //     // Function to get performance metrics
    //     const getPerformanceMetrics = () => {
    //         const performance = window.performance;
    //         const memory = performance.memory;
    //         return {
    //             jsHeapUsed: memory.usedJSHeapSize,
    //             jsHeapTotal: memory.totalJSHeapSize,
    //         };
    //     };

    //     // Function to gather WebRTC stats
    //     const gatherWebRTCStats = async () => {
    //         const stats = await peerConnection.current.getStats();
    //         let webRTCStats = {};
    //         stats.forEach(report => {
    //             if (report.type === 'candidate-pair' && report.state === 'succeeded') {
    //                 webRTCStats.currentRTT = report.currentRoundTripTime;
    //             }
    //             if (report.type === 'inbound-rtp') {
    //                 webRTCStats.packetsLost = report.packetsLost;
    //             }
    //         });
    //         return webRTCStats;
    //     };



    //     // Function to monitor YouTube streaming quality
    //     // const onYouTubeIframeAPIReady = () => {
    //     //     const player = new YT.Player('player', {
    //     //         events: {
    //     //             'onStateChange': onPlayerStateChange,
    //     //         },
    //     //     });
    //     // };

    //     // const onPlayerStateChange = (event) => {
    //     //     if (event.data === YT.PlayerState.BUFFERING) {
    //     //         console.log('Video buffering detected');
    //     //     }
    //     // };




    //     // Set interval to update stats every 5 seconds
    //     const interval = setInterval(async () => {
    //         const networkStats = await getNetworkStats();
    //         const performanceMetrics = getPerformanceMetrics();
    //         const webRTCStats = await gatherWebRTCStats();

    //         setStats({
    //             network: networkStats,
    //             performance: performanceMetrics,
    //             webRTC: webRTCStats,
    //         });
    //     }, 5000);

    //     // Cleanup interval on unmount
    //     return () => clearInterval(interval);
    // }, []);





    // useEffect(() => {


    //     // Function to get network stats
    //     const getNetworkStats = async () => {
    //         const stats = await peerConnection2.current.getStats();
    //         let networkStats = {};
    //         stats.forEach(report => {
    //             if (report.type === 'outbound-rtp' || report.type === 'inbound-rtp') {
    //                 networkStats[report.id] = report;
    //             }
    //         });
    //         return networkStats;
    //     };

    //     // Function to get performance metrics
    //     const getPerformanceMetrics = () => {
    //         const performance = window.performance;
    //         const memory = performance.memory;
    //         return {
    //             jsHeapUsed: memory.usedJSHeapSize,
    //             jsHeapTotal: memory.totalJSHeapSize,
    //         };
    //     };

    //     // Function to gather WebRTC stats
    //     const gatherWebRTCStats = async () => {
    //         const stats = await peerConnection2.current.getStats();
    //         let webRTCStats = {};
    //         stats.forEach(report => {
    //             if (report.type === 'candidate-pair' && report.state === 'succeeded') {
    //                 webRTCStats.currentRTT = report.currentRoundTripTime;
    //             }
    //             if (report.type === 'inbound-rtp') {
    //                 webRTCStats.packetsLost = report.packetsLost;
    //             }
    //         });
    //         return webRTCStats;
    //     };

    //     // Function to monitor YouTube streaming quality
    //     // const onYouTubeIframeAPIReady = () => {
    //     //     const player = new YT.Player('player', {
    //     //         events: {
    //     //             'onStateChange': onPlayerStateChange,
    //     //         },
    //     //     });
    //     // };

    //     // const onPlayerStateChange = (event) => {
    //     //     if (event.data === YT.PlayerState.BUFFERING) {
    //     //         console.log('Video buffering detected');
    //     //     }
    //     // };




    //     // Set interval to update stats every 5 seconds
    //     const interval = setInterval(async () => {
    //         const networkStats = await getNetworkStats();
    //         const performanceMetrics = getPerformanceMetrics();
    //         const webRTCStats = await gatherWebRTCStats();
    //         // monitorNetworkStats();

    //         setStats2({
    //             network: networkStats,
    //             performance: performanceMetrics,
    //             webRTC: webRTCStats,
    //         });
    //     }, 5000);

    //     // Cleanup interval on unmount
    //     return () => clearInterval(interval);
    // }, []);

    useEffect(() => {

        const socket = io('https://gabrialsignal.wingtrill.com/', {
            transports: ['websocket'],
            query: {
                callerId,
            },
        });
        socketref.current = socket;

        socketref.current.on('connect', () => {
            socketref.current.user = 12334;
        });
        socketref.current.on('seek', data => {
            const { value } = data;
             setseekvalue(data.value);
            socketvalueseek.current = data.value;
            console.log('Socket Value is in socket -->' , socketvalueseek.current);
            setseekcalled(true);

        });
        socketref.current.on('playClicked', () => {
            setplayclicked(true);
            // setpauseclicked(false);
            setplayclicked(false);
        });
        socketref.current.on('okpressed2', data => {
            let firstuser = data.firstuser;
            let seconduser = data.seconduser;
            let seekvalue = data.seekvalue;
            continousseekvalue.current = seekvalue;
            setseekvalue2(seekvalue);
 
         });
         socketref.current.on('okpressed', data => {
            let firstuser = data.firstuser;
            let seconduser = data.seconduser;
            let seekvalue = data.seekvalue;
            setseekseconduser(seconduser);
            socketseekvalueref2.current = seekvalue;
            seekseconduserref2.current = seconduser;
            seeekfirstuserref2.current = firstuser;



            console.log('First user Value is 247001 -->', seeekfirstuserref2.current);
            console.log('second user Value is 247001-->', seekseconduserref2.current);
            console.log('seek user Value is 247001-->', socketseekvalueref2.current);

 
           //  if(seekvalue === 0){
           //   const time = player.getCurrentTime();
           //   socketref.current.emit('syncvideo', { firstuser: seconduser, seconduser: firstuser, seekvalue: time })
           //  }else{
           //      player.seekTo(seekvalue);
 
           //  }
       
         
        //  setsocketseekcalled2(true);
        //  setsocketseekvalue2(data.seekvalue);
       
         
         
         // seekToTime(seekvalue);
 
         });
         socketref.current.on("endcast", data =>{
           

           socketref.current.emit("endcast" , {user : other2userref.current});
           console.log('Emitting the Socket -->' , other2userref.current);
         })

        socketref.current.on('syncvideo', data => {
           let firstuser = data.firstuser;
           let seconduser = data.seconduser;
           let seekvalue = data.seekvalue;
           setseekseconduser(seconduser);
           socketseekvalueref.current = seekvalue;
           seekseconduserref.current = seconduser;
           seeekfirstuserref.current = firstuser;

          //  if(seekvalue === 0){
          //   const time = player.getCurrentTime();
          //   socketref.current.emit('syncvideo', { firstuser: seconduser, seconduser: firstuser, seekvalue: time })
          //  }else{
          //      player.seekTo(seekvalue);

          //  }
        console.log('Sync is called 1 ' , firstuser);
        console.log('Sync is called 2 ' , seconduser);
        console.log('Sync is called 3 ' , seekvalue);
        
        setsocketseekcalled(true);
        setsocketseekvalue(data.seekvalue);
      
        
        
        // seekToTime(seekvalue);

        });
      
       






        socketref.current.on('pauseClicked', () => {
            // setplayclicked(false);
            setpauseclicked(true);

            setpauseclicked(false);
        });

        socketref.current.on('backClicked', () => {
            setbackclicked(true);
        });
        socketref.current.on('forwardClicked', () => {
            setforwardclicked(true);
        });

         socketref.current.on('userjoined', (data) => {
            let callerId = data.callerId;
            let firstusercallerid = data.firstusercallerid;

            socketref.current.emit('userjoined', { callerId: callerId, firstusercallerid: id });



        });



        return () => {
            // if (socket.current) {
            //   socket.current.disconnect();
            // }
        };

    }, []);



    useEffect(() => {
        const handleKeyPress = (event) => {
            // Log key code when pressing a key
            console.log('Key code:', event.keyCode);
            settvinteracted(true);
            setkeysdata((prevEventCodes) => [...prevEventCodes, event.code]);
        };

        // Add event listener for keydown events
        document.addEventListener('keydown', handleKeyPress);

        // Cleanup: Remove event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [callStatus]);







    useEffect(() => {
        socketref.current.on("receivelayoutcheck", (data) => {
            let layoutnumber = data.layoutvalue;
            console.log('Layout Value is >>>', layoutnumber);
            if (layoutnumber !== null) {
                setlayout(layoutnumber);
            }
        })
    }, [layout])










    useEffect(() => {
        function createFakeStream() {
            let ctx = new AudioContext();
            let oscillator = ctx.createOscillator();
            let dst = oscillator.connect(ctx.createMediaStreamDestination());
            oscillator.start();

            let canvas = document.createElement("canvas");
            canvas.width = 640;
            canvas.height = 480;
            canvas.getContext('2d').fillRect(0, 0, 640, 480);
            let stream = canvas.captureStream();

            const audioTrack = dst.stream.getAudioTracks()[0];
            const videoTrack = stream.getVideoTracks()[0];

            const fakeStream = new MediaStream([audioTrack, videoTrack]);

            return fakeStream;
        }

        const fakeStream = createFakeStream();
        setLocalStream(fakeStream);



      


        peerConnection.current.addEventListener("iceconnectionstatechange", (event) => {

            console.log('Ice candidate Connection >>>>>', event);
        });


        peerConnection2.current.addEventListener("iceconnectionstatechange", (event) => {

            console.log('Ice candidate Connection of peer connection 2 >>>>>', event);
        });

        if (peerConnection.current) {
            peerConnection.current.addEventListener('connectionstatechange', () => {
                if (peerConnection.current.connectionState === 'connected') {
                    setFirstConnected(true);
                }
            });
        }

        RTCPeerConnection.oniceconnectionstatechange = (event) => {

            console.log('Ice candidate Connection >>>>>', event);




        };

        peerConnection.current.addEventListener("connectionstatechange", (event) => {
            console.log('Connection state Connection >>>>>', event);
             if(event.currentTarget.iceConnectionState === "disconnected"){
                window.close();
                console.log('Peer Connection Disconnected');
            }
        });
        peerConnection2.current.addEventListener("connectionstatechange", (event) => {
            console.log('Connection state Connection peer connection 2 >>>>>', event);
        });

        RTCPeerConnection.onconnectionstatechange = (event) => {
            console.log('Connection state Connection >>>>>', event);
        };


        peerConnection.current.addStream(fakeStream);
        peerConnection2.current.addStream(fakeStream);


        peerConnection.current.onaddstream = event => {
            setRemoteStreams(event.stream);
        };
        peerConnection2.current.onaddstream = event => {
            setremotestream2(event.stream);
        };

        peerConnection.current.onicecandidate = event => {
            if (event.candidate) {
                sendICEcandidate({
                    calleeId: otherUserId,
                    rtcMessage: {
                        label: event.candidate.sdpMLineIndex,
                        id: event.candidate.sdpMid,
                        candidate: event.candidate.candidate,
                    },
                });
            } else {
                console.log('End of candidates.');
            }
        };
        peerConnection2.current.onicecandidate = event => {
            if (event.candidate) {
                sendICEcandidate({
                    calleeId: other2userref.current,
                    rtcMessage: {
                        label: event.candidate.sdpMLineIndex,
                        id: event.candidate.sdpMid,
                        candidate: event.candidate.candidate,
                    },
                });
            } else {
                console.log('End of candidates.');
            }
        };

        socketref.current.on('newCall', data => {
            remoteRTCMessage.current = data.rtcMessage;
            setOtherUserId(data.callerId);
            if (data.callerId !== otherUserId) {
                other2userref.current = data.callerId;
                // setisprocessCalled(true);
                console.log('Other User Id is -->' ,  other2userref.current);
              //  processAccept();
                  socketref.current.emit('sendVideoId', { videoId: videoid  , otherUserId: other2userref.current });
               
             


            }
            // setCallStatus('INCOMING_CALL');
            
                processAccept();
       

            // setisprocessCalled(true);


        });
        socketref.current.on('callAnswered', data => {
            remoteRTCMessage.current = data.rtcMessage;
            peerConnection.current
                .setRemoteDescription(new RTCSessionDescription(remoteRTCMessage.current))
                .then(() => {
                    setCallStatus('WEBRTC_ROOM');
                    console.log('Remote description set successfully.');
                })
                .catch(error => console.error('Error setting remote description:', error));
        });

        socketref.current.on('callAnswered', data => {
            remoteRTCMessage.current = data.rtcMessage;
            peerConnection2.current
                .setRemoteDescription(new RTCSessionDescription(remoteRTCMessage.current))
                .then(() => {
                    setCallStatus('WEBRTC_ROOM');
                    console.log('Remote description set successfully.');
                })
                .catch(error => console.error('Error setting remote description:', error));
        });

        socketref.current.on('ICEcandidate', data => {
            let message = data.rtcMessage;
            console.log("Received ICE candidate:", data);
            if (peerConnection.current) {
                peerConnection.current
                    .addIceCandidate(
                        new RTCIceCandidate({
                            candidate: message.candidate,
                            sdpMid: message.id,
                            sdpMLineIndex: message.label,
                        })
                    )
                    .then(data => {
                        console.log('SUCCESS');
                    })
                    .catch(err => {
                        console.log('Error', err);
                    });
            }




        });


        socketref.current.on('ICEcandidate', data => {
            let message = data.rtcMessage;
            console.log("Received ICE candidate:", data);
            if (peerConnection2.current) {
                peerConnection2.current
                    .addIceCandidate(
                        new RTCIceCandidate({
                            candidate: message.candidate,
                            sdpMid: message.id,
                            sdpMLineIndex: message.label,
                        })
                    )
                    .then(data => {
                        console.log('SUCCESS');
                    })
                    .catch(err => {
                        console.log('Error', err);
                    });
            }



        });

        socketref.current.on('userjoined', (data) => {
            let callerId = data.callerId;
            let firstusercallerid = data.firstusercallerid;

            socketref.current.emit('userjoined', { callerId: callerId, firstusercallerid: id });



        });
        socketref.current.on('receiveVideoId', data => {
            const { videoId } = data;
            console.log('Video Id is >>', videoId);
            if (videoId !== '' || videoId !== null) {
            
                    setVideoid(videoId);
                

                refvideoid.current = videoId;
                // if(other2userref.current !== null){
                //    socketref.current.emit('sendVideoId', { videoId: refvideoid.current  , otherUserId: other2userref.current}); 
                // }


            }
        });
        // if (videoid !== null && videoid !== '' && other2userref.current !== null) {
        //   socketref.current.emit('sendVideoId', { videoId: videoid  , otherUserId: other2userref.current});
        // }



        return () => {
            socketref.current.off('newCall');
            socketref.current.off('callAnswered');
            socketref.current.off('ICEcandidate');
            socketref.current.off('userjoined');
            socketref.current.off('receiveVideoId');

        };
    }, []);
   

    useEffect(() => {
        if (peerConnection.current && peerConnection.current.remoteDescription) {

            if (peerConnection.current.pendingIceCandidates) {
                peerConnection.current.pendingIceCandidates.forEach(candidate => {
                    peerConnection.current
                        .addIceCandidate(new RTCIceCandidate(candidate))
                        .then(() => {
                            console.log('Pending ICE candidate added successfully.');
                        })
                        .catch(error => {
                            console.error('Error adding pending ICE candidate:', error);
                        });
                });
                peerConnection.current.pendingIceCandidates = [];
            }
        }
    }, [peerConnection.current ? peerConnection.current.remoteDescription : null]);


    // socketref.current.on('userjoined', (data) => {
    //       let callerId = data.callerId;
    //       let firstusercallerid = data.firstusercallerid;

    // socketref.current.emit('userjoined', {callerId: callerId, firstusercallerid : id});



    //     });
    async function sendICEcandidate(data) {
        socketref.current.emit('ICEcandidate', data);
    }

    async function processCall() {
        const sessionDescription = await peerConnection.current.createOffer();
        await peerConnection.current.setLocalDescription(sessionDescription);
        sendCall({
            calleeId: otherUserId,
            rtcMessage: sessionDescription,
        });
    }
        useEffect(() => {
        if (otherUserId !== '' && callStatus === 'JOIN' && callerId !== null) {
               setTimeout(()=>{
                processCall();
               },4000);
               
       


        }
    },[])


    async function processAccept() {

      console.log('inside the Process Accept !!!')
        peerConnection2.current
            .setRemoteDescription(new RTCSessionDescription(remoteRTCMessage.current))
            .then(() => {
                peerConnection2.current.createAnswer().then(answer => {
                    peerConnection2.current
                        .setLocalDescription(answer)
                        .then(() => {
                            answerCall({
                                callerId: other2userref.current,
                                rtcMessage: answer,
                            });
                            setCallStatus('WEBRTC_ROOM');
                        })
                        .catch(error => {
                            console.error('Error setting local description:', error);
                        });
                });
            })
            .catch(error => {
                console.error('Error setting remote description:', error);
            });
    }

    useEffect(() => {
        if (videoid!== null && other2userref.current !== null) {

            // processAccept();

            socketref.current.emit('sendVideoId', { videoId: videoid, otherUserId: other2userref.current });

        }

    }, [videoid, other2userref.current])











    function answerCall(data) {
        socketref.current.emit('answerCall', data);
    }

    function sendCall(data) {
        socketref.current.emit('call', data);
    }




    useEffect(() => {
        // Play the video continuously until its current time is greater than 0
        const playContinuously2 = () => {
            if (player && seekvalue2 !== null && player.getCurrentTime() !== seekvalue2) {
              player.seekTo(seekvalue2);
            //   requestAnimationFrame(playContinuously2);
            // continousseekvalue.current=null;
            }
            console.log('Continous Seek Value Executing');
            
        };

        playContinuously2();
    },[seekvalue2]);

    

    useEffect(() => {
        // Play the video continuously until its current time is greater than 0
        let timeoutId;
        const playContinuously = () => {
            if (player && player.getCurrentTime() === 0) {
                player.playVideo();
                
                timeoutId = setTimeout(() => {
                    requestAnimationFrame(playContinuously);
                }, 4000);
            }
           
            
        };

        playContinuously();

        return () => clearTimeout(timeoutId);
    },);

    

     useEffect(() => {
        let timeoutId;

        const playContinuously2 = () => {
            if (player && seekseconduserref2.current && seeekfirstuserref2.current) {
                const time = player.getCurrentTime();
                socketref.current.emit('okpressed2', {
                    firstuser: seekseconduserref2.current,
                    seconduser: seeekfirstuserref2.current,
                    seekvalue: time
                });
                console.log('Executing the send Youtube duration');
                
                timeoutId = setTimeout(() => {
                    requestAnimationFrame(playContinuously2);
                }, 8000);
            }
        };

        playContinuously2();

        // Cleanup the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, );
  

    useEffect(() => {
        if (callStatus === 'WEBRTC_ROOM' && firstConnected && meetingid !== 'null') {

            const updateStatusToStarted = async () => {
                try {
                    const response = await fetch(`https://wwm-backend.wingtrill.com/api/workouts/status/${meetingid}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ status: 'started' })
                    });

                    if (!response.ok) {
                        throw new Error('Failed to update status to started');
                    }

                    console.log('Status updated to started successfully');
                } catch (error) {
                    console.error('Error updating status to started:', error);
                }
            };

            updateStatusToStarted();
        }
    }, [callStatus, firstConnected, meetingid]);


    const seekToTime = (time) => {
        console.log('Seek Value is Outside function --->', time)

        console.log('Seek Value is inside function --->', time)
        if(player){
player.seekTo(time*0.016);
        }
        

    };

    const onSeekHandler = (value) => {
        if (value && value !== undefined && player) {
            // const { value } = data;

            console.log('Seek Value is -->', value);

            player.seekTo(value);

        } else {
            console.error('Invalid data for seeking.');
        }
    };



    const onPlayHandler = () => {


     if(player){
       
       
           player.playVideo();
        }
       





    };
    const onPauseHandler = () => {
        if(player){
         player.pauseVideo();
        }
        
    };

    const unmuteVideo = () => {
        if (player) {
            player.unMute();
        }
    };



    useEffect(() => {
        if (playclicked) {
            onPlayHandler();


        }
    }, [playclicked]);

    
    useEffect(() => {
        if (pauseclicked) {
            onPauseHandler();
        }
    }, [pauseclicked]);




    const seekForward = () => {
        if(player){
        const currentTime = player.getCurrentTime();
        
         player.seekTo(currentTime + seekSeconds);
        }
        
    };

    const seekBackward = () => {
        // unmuteVideo();
        if(player){
  const currentTime = player.getCurrentTime();
        
        player.seekTo(currentTime - seekSeconds);
        }
      
    };
    const seekbar = () => {
        // unmuteVideo();
        // const currentTime = player.getCurrentTime();
       console.log('seeking to the value in function value is  -->', socketvalueseek.current)
       if(player){
 player.seekTo(socketvalueseek.current);
       }
       
    };

    const handlesocketseek = () =>{
        console.log('Value is 243434-->' , socketseekvalue);
        console.log('Value of refrence socketseek value-->' , socketseekvalueref.current);
            if(player){
              player.seekTo(socketseekvalueref.current);
            }
           
            if(seekseconduserref.current !== null){
                socketref.current.emit('syncvideo', { firstuser:  seekseconduserref.current, seconduser: null, seekvalue: socketseekvalueref.current })
            }
         
         
        
        
        
       }
       const handlesocketseek2 = () =>{
        console.log('Value is 243434-->' , socketseekvalue);
        console.log('Value of refrence socketseek value-->' , socketseekvalueref.current);
        

            if(socketseekvalueref2.current !== 0 && player){
               player.seekTo(socketseekvalueref2.current);
            }
            else{
            const time = player.getCurrentTime();
            socketref.current.emit('okpressed', { firstuser:  seekseconduserref.current, seconduser: null, seekvalue: time })
            }
         
         
        
        
        
       }
   
   
  
    useEffect(() => {
        if (backclicked) {

            seekBackward();
            setbackclicked(false);
        }
    }, [backclicked]);

    useEffect(() => {
        if (seekcalled) {
            seekbar();
            setseekcalled(false);
        }
    }, [seekcalled]);

    useEffect(()=>{
   if(socketseekcalled){
        handlesocketseek();
       setsocketseekcalled(false);
     }
    },[socketseekcalled])

    useEffect(()=>{
        if(socketseekcalled2){
             handlesocketseek2();
            setsocketseekcalled2(false);
          }
         },[socketseekcalled2])
    useEffect(() => {
        if (forwardclicked) {
            seekForward();
            setforwardclicked(false);
        }
    }, [forwardclicked]);

    console.log('Layout Value Change >>>', layout)



    return (
        <div>
            {callStatus === 'JOIN' && (
                <div style={{ width: '100%', height: '100%', flex: 1, justifyContent: 'center', textAlign: 'center' }}>
                    <h1>Please Wait We Are Connecting....</h1>
                    <h1>User  Value is: {id.toString()}</h1>



                    <button onClick={processCall}>Call</button>
                </div>
            )}
            {callStatus === 'INCOMING_CALL' && (

                <div>
                    <h1>Incoming Call from {other2userref.current}</h1>
                    <button onClick={processAccept}>Answer</button>
                </div>
            )}
            {callStatus === 'OUTGOING_CALL' && (
                <div>
                    <h1>Calling {otherUserId}</h1>
                    <button>Hang Up</button>
                </div>
            )}
            {/* {callStatus === 'WEBRTC_ROOM' && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '80%' }}>
              <iframe
                title="YouTube Video"
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${videoid}?autoplay=1&mute=1&enablejsapi=1`}
                frameBorder="0"
                allowFullScreen
                autoPlay
              ></iframe>
            </div>
            <div style={{ flex: 1, marginBottom: '-5px' }}>
  {localStream && (
    <video autoPlay muted={true} style={{ width: '100%' }} ref={video => (video && (video.srcObject = localStream))} />
  )}
  {
  remotestream2 && (
    <video autoPlay muted={true} style={{ width: '100%' }} ref={video => (video && (video.srcObject = remotestream2))} />
  )
}
</div>

{remoteStreams.map((stream, index) => (
  <div key={index} style={{ flex: 1 }}>
    {stream && (
      <video autoPlay muted={true} style={{ width: '100%', height: '100%' }} ref={video => (video && (video.srcObject = stream))} />
    )}
  </div>
))}

          </div>
        </div>
      )} */}


            {callStatus === 'WEBRTC_ROOM' && (
                <div style={{ height: '100%' }}>
                    <p style={{ textAlign: 'center', color: 'white', fontSize: 30 }}>Note : Press the Ok Button on Tv Remote to Play the youtube Video</p>
                    {meetingid !== 'null' &&
                        <p style={{ fontSize: 10 }}> Meeting id is -- {meetingid.toString()}</p>
                    }
                    {/* {tvinteracted && <p style={{ textAlign:'center' , color:'white' , fontSize:30 }}>Tv interacted !!</p>} */}
                    {/* <ul>
        {keysdata.map((code, index) => (
          <li key={index}>{code}</li>
        ))}
      </ul> */}
                    {layout === 0 ? (
                        // Layout 0: YouTube video on the left half with full height and remote streams (excluding remotestream2) on the right half
                        <div style={{ display: 'flex', height: '100%' }}>
                            {/* YouTube Video */}
                            <div style={{ flex: 1 }}>
                                <YouTube
                                    videoId={videoid}
                                    onReady={onReady}
                                    opts={{
                                        playerVars: {
                                            // autoplay:1,
                                            controls: 1,
                                            // mute:1

                                        },
                                    }}
                                />
                            </div>

                            {/* Remote Streams (excluding remotestream2) */}
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                  {
                                        remoteStreams && (
                                            <div style={{ flex: 1 }}>
                                                <video autoPlay style={{ width: '100%', height: '100%' }} ref={video => (video && (video.srcObject = remoteStreams))} />
                                            </div>
                                        )
                                    }
                            </div>
                        </div>
                    ) : layout === 1 ? (
                        // Layout 1: YouTube video and remotestream2 on top, remote streams below
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            {/* Top Row: Two small boxes (YouTube video and remotestream2) */}
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                {/* YouTube Video */}
                                <div style={{ flex: 1, marginRight: '5px' }}> {/* Add margin between the two blocks */}
                                    <YouTube
                                        videoId={videoid}
                                        onReady={onReady}
                                        opts={{
                                            playerVars: {
                                                // autoplay:1,
                                                controls: 1,
                                                // mute:1

                                            },
                                        }}
                                        style={{ width: '50%', height: '50%' }} // Adjust width and height as needed


                                    />

                                </div>


                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                    {remoteStreams.map((stream, index) => (
                                        <div key={index} style={{ width: '50%' }}> {/* Center the block and set its width */}
                                            {stream && (
                                                <video
                                                    autoPlay

                                                    style={{ width: '100%', height: '100%' }}
                                                    ref={(video) => video && (video.srcObject = stream)}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                                {/* RemoteStream2 */}
                                <div style={{ flex: 1, marginLeft: '5px' }}> {/* Add margin between the two blocks */}
                                    {remotestream2 && (
                                        <video
                                            autoPlay

                                            style={{ width: '100%', height: '100%' }}
                                            ref={(video) => video && (video.srcObject = remotestream2)}
                                        />
                                    )}
                                </div>
                            </div>

                            {/* Bottom Row: One larger block (Remote stream) */}

                        </div>

                    ) : (
                        // Layout 2: YouTube video, remotestream2, and remote streams displayed in a row
                        <div style={{ position: 'relative', height: '100vh' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* YouTube Video */}
                            <div style={{ width: '80%', height: '103%' }}> {/* Adjusted width */}
                                {videoid !== '' &&
                                    <YouTube
                                        videoId={videoid}
                                        onReady={onReady}
                                        opts={{
                                            playerVars: {
                                                controls: 1,
                                                // mute: 1
                                            },
                                        }}
                                    />
                                }
                            </div>
            
                            {/* Local and Remote Streams */}
                            <div style={{ width: '12vw', display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flex: 1, marginBottom: '-5px' }}>
                                    {remotestream2 && (
                                        <video autoPlay style={{ width: '100%' }} ref={video => (video && (video.srcObject = remotestream2))} />
                                    )}
                                </div>
            
                                {remoteStreams && (
                                    <div style={{ flex: 1 }}>
                                        <video autoPlay style={{ width: '100%', height: '100%' }} ref={video => (video && (video.srcObject = remoteStreams))} />
                                    </div>
                                )}
                            </div>
                        </div>
            
                        {/* Overlaying Stats */}
                      
                    </div>
                    )}
                </div>
            )}





        </div>
    );
}

export default App;
